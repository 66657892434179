var render = function () {
  var _vm$event$car;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-lazy', {
    attrs: {
      "options": {
        threshold: 0.5
      }
    }
  }, [_c('div', {
    staticClass: "event-container",
    class: [_vm.eventTypeClass, _vm.highlight],
    style: {
      backgroundColor: _vm.event.service.background_color,
      color: _vm.event.service.font_color
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('event:click', _vm.event.id);
      }
    }
  }, [_vm.event.partner_only ? _c('div', {
    staticClass: "partner-only-label mb-2 pa-1 pl-3"
  }, [_vm._v(" 協力会社のみ ")]) : _vm._e(), _c('v-row', {
    staticClass: "px-2 text-left",
    class: _vm.event.partner_only ? 'pb-2' : 'py-2',
    attrs: {
      "align": "center",
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "event-time-begin",
    class: !_vm.threeDaysView ? '' : 'pb-0',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-2",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.event.task_start_time) + " ")]), _vm.threeDaysView ? _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.event.site.name) + " ")]) : _vm._e(), _vm.weatherInfo ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    class: _vm.weatherBackgroundClass,
    attrs: {
      "size": _vm.threeDaysView ? 30 : 24
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.weatherInfo,
      "max-width": "25"
    }
  })], 1)], 1) : _vm._e(), _vm.threeDaysView ? _c('v-col', {
    staticClass: "city-txt mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.event.site.city) + " ")]) : _vm._e()], 1)], 1), _vm.event.car ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.event.service.font_color,
      "small": ""
    }
  }, [_vm._v("mdi-car")]), _c('div', [_c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s((_vm$event$car = _vm.event.car) === null || _vm$event$car === void 0 ? void 0 : _vm$event$car.car_class))])])], 1)]) : _vm._e(), !_vm.threeDaysView ? _c('v-col', {
    staticClass: "event-location font-weight-bold",
    class: _vm.importantMessage ? '' : '',
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.event.site.name) + " ")]) : _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "order": "2",
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "member-row",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.members, function (member, index) {
    return _c('v-col', {
      key: "member-".concat(index),
      staticClass: "members-avatar",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      staticClass: "member-avatar",
      class: _vm.isLeader(member) ? 'leader' : '',
      attrs: {
        "size": _vm.avatarSize
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getMemberPhoto(member)
      }
    })]), _c('div', {
      staticClass: "text-center mt-1 user-name"
    }, [_vm._v(" " + _vm._s(member.last_name) + " ")])], 1);
  }), 1)], 1), _c('v-col', {
    staticClass: "event-important-message",
    attrs: {
      "cols": "12",
      "order": "1"
    }
  }, [_c('span', {
    attrs: {
      "title": _vm.importantMessage
    }
  }, [_vm._v(" " + _vm._s(_vm.truncateMessage(_vm.importantMessage)) + " ")])])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }