<template>
  <v-lazy :options="{ threshold: 0.5 }">
    <div class="event-container" :class="[eventTypeClass, highlight]" :style="{
      backgroundColor: event.service.background_color,
      color: event.service.font_color
    }" @click="$emit('event:click', event.id)">
      <div class="partner-only-label mb-2 pa-1 pl-3" v-if="event.partner_only">
        協力会社のみ
      </div>
      <v-row align="center" dense class="px-2 text-left" :class="event.partner_only ? 'pb-2' : 'py-2'">
        <v-col cols="12" class="event-time-begin" :class="!threeDaysView ? '' : 'pb-0'">
          <v-row align="center" no-gutters>
            <v-col cols="auto" class="pr-2">
              {{ event.task_start_time }}
            </v-col>
            <v-col v-if="threeDaysView" cols="auto" class="flex-grow-1">
              {{ event.site.name }}
            </v-col>
            <v-col v-if="weatherInfo" cols="auto">
              <v-avatar :size="threeDaysView ? 30 : 24" :class="weatherBackgroundClass">
                <v-img :src="weatherInfo" max-width="25" />
              </v-avatar>
            </v-col>
            <v-col v-if="threeDaysView" cols="12" class="city-txt mt-1">
              {{ event.site.city }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="event.car">
          <div class="d-flex align-center">
            <v-icon :color="event.service.font_color" small>mdi-car</v-icon>
            <div>
              <span class="ml-1"> {{ event.car?.car_class }}</span>
            </div>
          </div>
        </v-col>
        <!--      <v-col v-if="weatherInfo && !threeDaysView" cols="2">-->
        <!--        <v-img :src="weatherInfo" max-width="25" />-->
        <!--      </v-col>-->
        <v-col v-if="!threeDaysView" cols="auto" class="event-location font-weight-bold"
          :class="importantMessage ? '' : ''">
          {{ event.site.name }}
        </v-col>
        <v-col v-else order="2" cols="12" class="pt-0">
          <v-row class="member-row" no-gutters>
            <v-col cols="auto" v-for="(member, index) in members" :key="`member-${index}`" class="members-avatar">
              <v-avatar class="member-avatar" :class="isLeader(member) ? 'leader' : ''" :size="avatarSize">
                <img alt="avatar" :src="getMemberPhoto(member)" />
              </v-avatar>
              <div class="text-center mt-1 user-name">
                {{ member.last_name }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="event-important-message" order="1">
          <span :title="importantMessage">
            {{ truncateMessage(importantMessage) }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-lazy>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Event',
  props: {
    event: {
      type: Object,
      default: () => { },
      required: true
    },
    threeDaysView: {
      type: Boolean,
      default: false,
      required: false
    },
    color: {
      type: String,
      default: '',
      required: false
    },
    clickable: {
      type: Boolean,
      default: true,
      required: false
    },
    partnerOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    nightView() {
      let hour = this.event.task_start_time.split(':')[0]
      let minute = this.event.task_start_time.split(':')[1]

      let nightTime = dayjs()
        .hour(22)
        .minute(0)

      let currentTime = dayjs()
        .hour(hour)
        .minute(minute)

      return currentTime.isAfter(nightTime)
    },
    eventTypeClass() {
      let eventClass = ''
      // eventClass = this.nightView ? 'night' : 'day'
      eventClass = this.clickable ? ' clickable' : ''
      return eventClass
    },
    avatarSize() {
      let size = 25
      if (this.$vuetify.breakpoint.name === 'lg') size = 30
      else if (this.$vuetify.breakpoint.name === 'xl') size = 30
      return size
    },
    members() {
      if (!this.event.task_member.length) return []
      let count = 2
      if (this.$vuetify.breakpoint.name === 'md') count = 2
      else if (this.$vuetify.breakpoint.name === 'lg') count = 4
      else if (this.$vuetify.breakpoint.name === 'xl') count = 6

      if (!this.event.leader_id) return this.event.task_member

      let leader = this.event.task_member.find(
        member => member.id === this.event.leader.id
      )
      let leaderIdx = this.event.task_member.findIndex(
        member => member.id === this.event.leader.id
      )
      let members = [...this.event.task_member]
      if (leader && leaderIdx) {
        members.unshift(leader)
        members.splice(leaderIdx + 1, 1)
      }
      return members.slice(0, count)
    },
    importantMessage() {
      return this.event.important_notice || ''
    },
    weatherInfo() {
      if (!this.event.weather_info) return ''
      return `http://openweathermap.org/img/wn/${this.event.weather_info.weather[0].icon}@2x.png`
    },
    weatherBackgroundClass() {
      if (!this.event.weather_info) return ''
      return `background-${this.event.time === 0 ? 'day' : 'night'}`
    }
  },
  data() {
    return {
      highlight: this.$route.query?.task && this.$route.query?.task == this.event.id ? 'highlight' : ''
    }
  },
  methods: {
    truncateMessage(msg) {
      if (msg.length > 20) {
        return msg.substring(0, 20) + '...'
      }
      return msg
    },

    isLeader(member) {
      return this.event.leader?.id === member.id
    },

    getMemberPhoto(member) {
      if (member.profile_image) return `${member.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    }
  },

  created() {
    setTimeout(() => {
      this.highlight = null
    }, 15000)
  }
}
</script>

<style lang="scss" src="./Event.scss" scoped>

</style>
