<template>
  <v-container class="calendar-container pt-0">
    <v-row class="header">
      <template v-for="(day, dayIndex) in days">
        <v-col class="date text-center" :key="`day-header-${dayIndex}`" :class="$route.query?.date == day.fullDate && $route.query?.date ? highlightDate : ''">
          <template v-if="dayIndex">
            <div class="day-names">
              {{ daysInAWeek[dayIndex - 1] }}
            </div>
            <div class="day-date" :class="{ 'today': isToday(day) }">
              {{ getDayLabel(day) }}
            </div>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-icon v-bind="attrs" v-on="on">$question</v-icon>
              </template>
              <div class="tooltip-message">
                1日(昼/夜)で1件以上のタスクがある場合、昼と夜と別々に1カウントします。
                <br />
                昼と夜のそれぞれの稼働数から簡易の月稼働率を表示します。
              </div>
            </v-tooltip>
          </template>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="loading">
      <v-col cols="12" class="px-0 py-0">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row class="employee-task-view" v-for="(employee, employeeIndex) in employees" :key="`employee-${employeeIndex}`">
      <v-col cols="12" class="px-0 py-0">
        <div class="employee-task-container">
          <div class="slot employee pa-2 pa-sm-1 pa-lg-2">
            <v-layout fill-height align-center>
              <v-row align="center" class="text-center" no-gutters>
                <v-col cols="12" class="mb-2">
                  <v-avatar size="60">
                    <img alt="avatar" :src="getMemberPhoto(employee)" />
                  </v-avatar>
                </v-col>

                <v-col cols="12" class="mb-2 font-weight-medium employee-name">
                  {{ employee.full_name || employee.name }}
                </v-col>

                <template v-if="!partnerMode">
                  <v-col cols="12" class="employee-detail mb-2">
                    <v-row no-gutters align="center" justify="center">
                      <v-col>
                        <span class="employee-task-label">昼</span>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-baseline">
                          <span class="employee-task-number font-weight-medium">
                            {{ employee.busyDays.day }}
                          </span>
                          <span class="employee-task-txt font-weight-medium">
                            日
                          </span>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="employee-task-percentage font-weight-medium" :class="
                          employee.busyPercentage.total > busyThreshold
                            ? 'busy'
                            : ''
                        ">
                          {{ employee.busyPercentage.day.toFixed(1) }}%
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="employee-detail mb-2">
                    <v-row no-gutters align="center" justify="center">
                      <v-col>
                        <span class="employee-task-label">夜</span>
                      </v-col>
                      <v-col>
                        <div class="d-flex align-baseline">
                          <span class="employee-task-number font-weight-medium">
                            {{ employee.busyDays.night }}
                          </span>
                          <span class="employee-task-txt font-weight-medium">
                            日
                          </span>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="employee-task-percentage" :class="
                          employee.busyPercentage.total > busyThreshold
                            ? 'busy'
                            : ''
                        ">
                          {{ employee.busyPercentage.night.toFixed(1) }}%
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col>
                    <div class="employee-task-summary">
                      昼{{ employee.availableDays.day }} / 夜{{
                          employee.availableDays.night
                      }}
                      稼働
                    </div>
                  </v-col>
                </template>

                <template v-else>
                  <v-col cols="12">
                    {{ employee.tasks_count }} タスク
                  </v-col>
                </template>

                <v-col cols="12" class="employee-task-label mt-2">
                  {{ currentPeriodMonth.add(1, 'month').format('MM月分') }}
                </v-col>
              </v-row>
            </v-layout>
          </div>

          <template v-for="(day, dayIndex) in days">
            <template v-if="dayIndex">
              <div class="slot day" :key="`day-slot-${dayIndex}`" :class="$route.query?.date == day.fullDate ? highlightDate : ''">
                <event v-for="(event, i) in getUserTasks(
                  employee,
                  day.fullDate,
                  'day'
                )" :key="`event-day-item-${i}`" :important-message="event.message" :event="event"
                  @event:click="$emit('event:click', $event)" />
                <template v-if="
                  holidayCheck(employee.offdays, 'day_status', day.fullDate)
                ">
                  <v-layout align-center>
                    <v-col class="text-center">
                      <v-icon size="72">
                        $employeeOff
                      </v-icon>
                    </v-col>
                  </v-layout>
                </template>
              </div>

              <div class="slot night" :key="`night-slot-${dayIndex}`" :class="$route.query?.date == day.fullDate ? highlightDate : ''">
                <event v-for="(event, i) in getUserTasks(
                  employee,
                  day.fullDate,
                  'night'
                )" :key="`event-night-item-${i}`" night-view :important-message="event.message" :event="event"
                  @event:click="$emit('event:click', $event)" />
                <template v-if="
                  holidayCheck(employee.offdays, 'night_status', day.fullDate)
                ">
                  <v-layout align-center>
                    <v-col class="text-center">
                      <v-icon size="72">
                        $employeeOff
                      </v-icon>
                    </v-col>
                  </v-layout>
                </template>
              </div>
            </template>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Event from '@/components/admin/partials/Calendar/Event/Event'
import { mapGetters } from 'vuex'
import { cloneDeep, chain } from 'lodash'
import dayjs from 'dayjs'
import { round } from 'mathjs'

export default {
  name: 'TaskViewCalendar',
  components: { Event },
  props: {
    month: {
      type: Object,
      default: () => { },
      required: false
    },
    unassignedEmployees: {
      type: Array,
      default: () => [],
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    partnerMode: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  created() {
    this.init()
    setTimeout(() => {
      this.highlightDate = null
    }, 15000)
  },
  watch: {
    month() {
      this.init()
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'allTasks', 'allPartners']),

    currentMonth() {
      return this.month
    },

    currentPeriodMonth() {
      let currentDate = this.month.date()
      if (currentDate < 21) {
        return this.month.subtract(1, 'month').date(21)
      }
      return this.month.date(21)
    },

    dayArray() {
      return this.dayChunks(this.days, this.daysInAWeek.length)
    },

    workDays() {
      let currentDate = this.month.date()
      let start
      let end
      if (currentDate < 21) {
        start = this.month.subtract(1, 'month').date(21)
        end = this.month.date(20)
      } else {
        start = this.month.date(21)
        end = this.month.add(1, 'month').date(20)
      }
      return end.diff(start, 'day') + 1
    },

    employees() {
      if (!this.partnerMode) {
        let users = cloneDeep(this.allUsers)
        users?.forEach(user => {
          let tasks = chain(user.tasks)
            .groupBy('date')
            .value()

          user.busyDays = {
            day: 0,
            night: 0
          }

          user.busyPercentage = {
            day: 0,
            night: 0,
            total: 0
          }

          user.availableDays = {
            day: this.workDays,
            night: this.workDays
          }

          for (const [key, value] of Object.entries(tasks)) {
            tasks[key] = {
              day: value.filter(task => task.time === 0),
              night: value.filter(task => task.time === 1)
            }
            if (
              dayjs(key).isBetween(
                this.currentPeriodMonth,
                this.currentPeriodMonth.add(1, 'month').date(20),
                null,
                '[]'
              )
            ) {
              if (tasks[key].day.length) {
                user.busyDays.day += 1
              }
              if (tasks[key].night.length) {
                user.busyDays.night += 1
              }
            }
          }

          user.availableDays.day -= this.getUserOffdays(user, 'day')
          user.availableDays.night -= this.getUserOffdays(user, 'night')

          user.busyPercentage.day = this.getPercentage(
            user.busyDays.day,
            user.availableDays.day
          )
          user.busyPercentage.night = this.getPercentage(
            user.busyDays.night,
            user.availableDays.night
          )
          user.busyPercentage.total =
            user.busyPercentage.day + user.busyPercentage.night

          user.tasks = tasks
        })
        return users
      }
      let partners = cloneDeep(this.allPartners)
      partners?.forEach(partner => {
        let tasks = chain(partner.tasks)
          .groupBy('date')
          .value()

        for (const [key, value] of Object.entries(tasks)) {
          tasks[key] = {
            day: value.filter(task => task.time === 0),
            night: value.filter(task => task.time === 1)
          }
        }
        partner.tasks = tasks
      })

      return partners
    }
  },
  data: () => ({
    daysInAWeek: ['月', '火', '水', '木', '金', '土', '日'],
    days: [],
    busyThreshold: 75,
    highlightDate: 'highlight-date'
  }),
  methods: {
    dayChunks(array, chunk_size) {
      return Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map(begin => {
          let arr = array.slice(begin, begin + chunk_size)
          arr.unshift({
            header: true
          })

          return arr
        })
    },

    init() {
      let days = []

      let beginDate = this.currentMonth.startOf('week')
      let endDate = this.currentMonth.endOf('week')

      days.push({ header: true })

      while (beginDate.isSameOrBefore(endDate, 'day')) {
        days.push({
          date: beginDate.format('D'),
          month: beginDate.format('M'),
          monthName: beginDate.format('MMMM'),
          fullDate: beginDate.format('YYYY-MM-DD'),
          type: beginDate.isBefore(this.currentMonth, 'month')
            ? 'trailing'
            : beginDate.isAfter(this.currentMonth, 'month')
              ? 'leading'
              : 'current',
          first: beginDate.date() === 1
        })

        beginDate = beginDate.add(1, 'day')
      }
      this.days = days
    },

    getDayLabel(day) {
      return day.first ? `${day.month}/${day.date}` : day.date
    },

    holidayCheck(offdays, type, date) {
      return (
        offdays?.find(offday => offday.date === date && offday[type] === 2) ||
        false
      )
    },

    getUserTasks(employee, date, type) {
      if (!employee.tasks[date]) return []

      return employee.tasks[date][type]?.sort((a, b) => {
        if (!a.task_start_time && b.task_start_time) return -1
        else if (a.task_start_time && !b.task_start_time) return 1
        let dateA = dayjs(`${a.date} ${a.task_start_time}`)
        let dateB = dayjs(`${b.date} ${b.task_start_time}`)
        return dateA.isBefore(dateB) ? -1 : 1
      })
    },

    getPercentage(a, b) {
      let result = (a / b) * 100
      return round(result, 1)
    },

    getMemberPhoto(member) {
      if (member.profile_image) return `${member.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    },

    getUserOffdays(user, type) {
      return (
        user.offdays?.filter(offday => {
          return (
            offday[`${type}_status`] === 2 &&
            dayjs(offday.date).isBetween(
              this.currentPeriodMonth,
              this.currentPeriodMonth.add(1, 'month').date(20),
              null,
              '[]'
            )
          )
        }).length || 0
      )
    },

    isToday(date) {
      return date.fullDate === dayjs().format('YYYY-MM-DD')
    }
  }
}
</script>

<style src="./TaskView.scss" lang="scss" scoped>
</style>
