var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "calendar-container pt-0"
  }, [_c('v-row', {
    staticClass: "header"
  }, [_vm._l(_vm.days, function (day, dayIndex) {
    var _vm$$route$query, _vm$$route$query2;

    return [_c('v-col', {
      key: "day-header-".concat(dayIndex),
      staticClass: "date text-center",
      class: ((_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.date) == day.fullDate && (_vm$$route$query2 = _vm.$route.query) !== null && _vm$$route$query2 !== void 0 && _vm$$route$query2.date ? _vm.highlightDate : ''
    }, [dayIndex ? [_c('div', {
      staticClass: "day-names"
    }, [_vm._v(" " + _vm._s(_vm.daysInAWeek[dayIndex - 1]) + " ")]), _c('div', {
      staticClass: "day-date",
      class: {
        'today': _vm.isToday(day)
      }
    }, [_vm._v(" " + _vm._s(_vm.getDayLabel(day)) + " ")])] : [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var attrs = _ref.attrs,
              on = _ref.on;
          return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("$question")])];
        }
      }], null, true)
    }, [_c('div', {
      staticClass: "tooltip-message"
    }, [_vm._v(" 1日(昼/夜)で1件以上のタスクがある場合、昼と夜と別々に1カウントします。 "), _c('br'), _vm._v(" 昼と夜のそれぞれの稼働数から簡易の月稼働率を表示します。 ")])])]], 2)];
  })], 2), _vm.loading ? _c('v-row', [_c('v-col', {
    staticClass: "px-0 py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.employees, function (employee, employeeIndex) {
    return _c('v-row', {
      key: "employee-".concat(employeeIndex),
      staticClass: "employee-task-view"
    }, [_c('v-col', {
      staticClass: "px-0 py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "employee-task-container"
    }, [_c('div', {
      staticClass: "slot employee pa-2 pa-sm-1 pa-lg-2"
    }, [_c('v-layout', {
      attrs: {
        "fill-height": "",
        "align-center": ""
      }
    }, [_c('v-row', {
      staticClass: "text-center",
      attrs: {
        "align": "center",
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "mb-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-avatar', {
      attrs: {
        "size": "60"
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getMemberPhoto(employee)
      }
    })])], 1), _c('v-col', {
      staticClass: "mb-2 font-weight-medium employee-name",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(employee.full_name || employee.name) + " ")]), !_vm.partnerMode ? [_c('v-col', {
      staticClass: "employee-detail mb-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": "",
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', [_c('span', {
      staticClass: "employee-task-label"
    }, [_vm._v("昼")])]), _c('v-col', [_c('div', {
      staticClass: "d-flex align-baseline"
    }, [_c('span', {
      staticClass: "employee-task-number font-weight-medium"
    }, [_vm._v(" " + _vm._s(employee.busyDays.day) + " ")]), _c('span', {
      staticClass: "employee-task-txt font-weight-medium"
    }, [_vm._v(" 日 ")])])]), _c('v-col', [_c('div', {
      staticClass: "employee-task-percentage font-weight-medium",
      class: employee.busyPercentage.total > _vm.busyThreshold ? 'busy' : ''
    }, [_vm._v(" " + _vm._s(employee.busyPercentage.day.toFixed(1)) + "% ")])])], 1)], 1), _c('v-col', {
      staticClass: "employee-detail mb-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": "",
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', [_c('span', {
      staticClass: "employee-task-label"
    }, [_vm._v("夜")])]), _c('v-col', [_c('div', {
      staticClass: "d-flex align-baseline"
    }, [_c('span', {
      staticClass: "employee-task-number font-weight-medium"
    }, [_vm._v(" " + _vm._s(employee.busyDays.night) + " ")]), _c('span', {
      staticClass: "employee-task-txt font-weight-medium"
    }, [_vm._v(" 日 ")])])]), _c('v-col', [_c('div', {
      staticClass: "employee-task-percentage",
      class: employee.busyPercentage.total > _vm.busyThreshold ? 'busy' : ''
    }, [_vm._v(" " + _vm._s(employee.busyPercentage.night.toFixed(1)) + "% ")])])], 1)], 1), _c('v-col', [_c('div', {
      staticClass: "employee-task-summary"
    }, [_vm._v(" 昼" + _vm._s(employee.availableDays.day) + " / 夜" + _vm._s(employee.availableDays.night) + " 稼働 ")])])] : [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(employee.tasks_count) + " タスク ")])], _c('v-col', {
      staticClass: "employee-task-label mt-2",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(_vm.currentPeriodMonth.add(1, 'month').format('MM月分')) + " ")])], 2)], 1)], 1), _vm._l(_vm.days, function (day, dayIndex) {
      var _vm$$route$query3, _vm$$route$query4;

      return [dayIndex ? [_c('div', {
        key: "day-slot-".concat(dayIndex),
        staticClass: "slot day",
        class: ((_vm$$route$query3 = _vm.$route.query) === null || _vm$$route$query3 === void 0 ? void 0 : _vm$$route$query3.date) == day.fullDate ? _vm.highlightDate : ''
      }, [_vm._l(_vm.getUserTasks(employee, day.fullDate, 'day'), function (event, i) {
        return _c('event', {
          key: "event-day-item-".concat(i),
          attrs: {
            "important-message": event.message,
            "event": event
          },
          on: {
            "event:click": function eventClick($event) {
              return _vm.$emit('event:click', $event);
            }
          }
        });
      }), _vm.holidayCheck(employee.offdays, 'day_status', day.fullDate) ? [_c('v-layout', {
        attrs: {
          "align-center": ""
        }
      }, [_c('v-col', {
        staticClass: "text-center"
      }, [_c('v-icon', {
        attrs: {
          "size": "72"
        }
      }, [_vm._v(" $employeeOff ")])], 1)], 1)] : _vm._e()], 2), _c('div', {
        key: "night-slot-".concat(dayIndex),
        staticClass: "slot night",
        class: ((_vm$$route$query4 = _vm.$route.query) === null || _vm$$route$query4 === void 0 ? void 0 : _vm$$route$query4.date) == day.fullDate ? _vm.highlightDate : ''
      }, [_vm._l(_vm.getUserTasks(employee, day.fullDate, 'night'), function (event, i) {
        return _c('event', {
          key: "event-night-item-".concat(i),
          attrs: {
            "night-view": "",
            "important-message": event.message,
            "event": event
          },
          on: {
            "event:click": function eventClick($event) {
              return _vm.$emit('event:click', $event);
            }
          }
        });
      }), _vm.holidayCheck(employee.offdays, 'night_status', day.fullDate) ? [_c('v-layout', {
        attrs: {
          "align-center": ""
        }
      }, [_c('v-col', {
        staticClass: "text-center"
      }, [_c('v-icon', {
        attrs: {
          "size": "72"
        }
      }, [_vm._v(" $employeeOff ")])], 1)], 1)] : _vm._e()], 2)] : _vm._e()];
    })], 2)])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }